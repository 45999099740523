.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-scale-90);
  font: var(--text-md);
  letter-spacing: var(--text-md-letter-spacing);
  margin-bottom: 0.1875rem;
}

.field {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-scale-30);
  border-radius: 0.375rem;
}

.password {
  width: 100%;
  flex: 1;
  padding: 0;
  margin: 0;
  border: none;
  color: var(--grey-scale-90);
  font: var(--text-lg);
  letter-spacing: var(--text-lg-letter-spacing);
}

.password::placeholder {
  color: var(--grey-scale-20);
}

.field:has(.password:focus) {
  border: 1px solid var(--grey-scale-90);
}

.eyesIcon {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
