#App {
  max-width: 450px;
  /* min-height: 667px; */
  height: 100vh;
  margin: 0 auto;
  background-color: white;
  overflow-y: auto;
  -ms-overflow-style: none; /*IE, Edge*/
  scrollbar-width: none; /*Firefox*/
  ::-webkit-scrollbar {
    display: none; /*Chrome, Safari, Opera*/
    width: 0px;
  }
}
