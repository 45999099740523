.nav {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.4375rem 0;
  padding-right: 1.25rem;
}

.back {
  width: 2.25rem;
  margin-left: auto;
}

.title {
  flex: 1;
  text-align: center;
  font: var(--title-sm);
  letter-spacing: var(--title-sm-letter-spacing);
  color: var(--primary-skyblue);
}

.box {
  width: 2.25rem;
  display: flex;
  justify-content: end;
  align-items: center;
}
