.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.title {
  color: var(--primary-skyblue);
  font: var(--title-exlg);
  letter-spacing: var(--title-exlg-letter-spacing);
  margin-left: 0.125rem;
}

.description {
  color: var(--grey-scale-75);
  font: var(--text-lg);
  letter-spacing: var(--text-lg-letter-spacing);
}
