.container {
  height: 100vh;
  padding: 0 23px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  font: var(--title-sm);
  color: var(--grey-scale-45);
  text-align: center;
}

.emphasis {
  color: var(--primary-skyblue);
}
