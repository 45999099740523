.container {
  padding: 0 1.4375rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.top {
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.name {
  font: var(--title-md);
  color: var(--grey-scale-75);
  letter-spacing: var(--title-md-letter-spacing);
}

.bell {
  position: relative;
}

.alertCount {
  padding: 2px 5px;
  margin: 0;
  border-radius: 2.375rem;
  background-color: var(--alert-under-5);
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--grey-scale-0);
  position: absolute;
  top: -7px;
  right: -10px;
}

.item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.0625rem 1.125rem;
  border-bottom: var(--grey-scale-10) 1px solid;
  font: var(--text-lg-emphasis);
  letter-spacing: var(--text-lg-emphasis-letter-spacing);
  color: var(--grey-scale-60);
}
