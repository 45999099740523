.title {
  width: 68%;
}

.category,
.date {
  flex: 1;
}

.date {
  white-space: pre-wrap;
}

.clip {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
