label {
  display: block;
  color: var(--grey-scale-90);
  font: var(--text-md);
  letter-spacing: var(--text-md-letter-spacing);
  margin-bottom: 0.1875rem;
}

.label {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid var(--grey-scale-30);
  box-sizing: border-box;
  cursor: pointer;
}

.select.active .label {
  border: 1px solid var(--grey-scale-90);
}

.label button {
  display: block;
  width: 100%;
  height: 2rem;
  padding: 0;
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  color: var(--grey-scale-90);
  font: var(--text-lg);
  letter-spacing: var(--text-lg-letter-spacing);
  cursor: pointer;
}

.label .placeholder {
  color: var(--grey-scale-20);
}

.optionList {
  padding: 0;
  margin: 1rem 0 0 0;
  max-height: 0;
  overflow: hidden;
  list-style-type: none;
  transition: 0.3s ease-in;
}

.select.active .optionList {
  max-height: 500px;
}

.optionItem {
  padding: 1rem;
  border-bottom: 1px solid var(--grey-scale-20);
}

.optionItem:last-child {
  border-bottom: none;
}

/* 스크롤 커스텀 */
.optionList::-webkit-scrollbar {
  width: 6px;
}

.optionList::-webkit-scrollbar-track {
  background: transparent;
}

.optionList::-webkit-scrollbar-thumb {
  background: #303030;
  border-radius: 45px;
}

.optionList::-webkit-scrollbar-thumb:hover {
  background: #303030;
}
