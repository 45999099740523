.bottomSheet {
  width: 100%;
  max-width: 450px;
  padding: 0 1.625rem 4rem 1.625rem;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 2.1875rem;
  border-top-right-radius: 2.1875rem;
  background-color: var(--grey-scale-0);
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  box-shadow:
    0px -32px 13px rgba(83, 106, 116, 0.01),
    0px -18px 11px rgba(83, 106, 116, 0.05),
    0px -8px 8px rgba(83, 106, 116, 0.09),
    0px -2px 4px rgba(83, 106, 116, 0.15);
  animation: slideUp 0.2s ease;
}

.header {
  height: 40px;
  text-align: center;
}

.handler {
  display: inline-block;
  width: 4rem;
  height: 0.3125rem;
  border-radius: 100px;
  background-color: var(--grey-scale-20);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
