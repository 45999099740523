.nav {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1rem;
  background-color: var(--grey-scale-0);
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow:
    0px 11px 5px rgba(61, 86, 113, 0.02),
    0px 6px 4px rgba(61, 86, 113, 0.06),
    0px 3px 3px rgba(61, 86, 113, 0.11),
    0px 1px 2px rgba(61, 86, 113, 0.12);
}

.menu {
  width: 2.25rem;
}

.menu img {
  width: 100%;
}

.logo {
  width: 6.125rem;
  display: flex;
  align-items: center;
}

.logo img {
  width: 100%;
}

.mypage {
  width: 2.25rem;
}

.mypage img {
  width: 100%;
}

.card {
  width: 100%;
  padding: 0 1.4375rem;
  position: absolute;
  top: 4.875rem;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}
