.umbrella {
  position: absolute;
  right: -0.3125rem;
  top: 3.125rem;
  text-align: right;
  animation: login-slidein-right-to-left 1s;
}

.umbrella img {
  width: 22.25rem;
}

.dim {
  position: absolute;
  width: 100%;
  height: 34rem;
  left: 0;
  top: -3.125rem;
  background: linear-gradient(
    156.5deg,
    #ffffff 42.3%,
    rgba(255, 255, 255, 0) 71.23%
  );
  mix-blend-mode: normal;
}

@keyframes login-slidein-right-to-left {
  0% {
    transform: translateX(25rem) rotate(60deg);
  }

  50% {
    transform: translateX(25rem) rotate(60deg);
  }

  80% {
    transform: rotate(-1deg);
  }

  100% {
    transform: rotate(0);
  }
}
