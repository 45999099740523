.button {
  padding: 0.875rem 1.25rem;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  background-color: var(--grey-scale-0);
  border: 1px solid var(--grey-scale-20);
  cursor: pointer;
}

.button:active {
  filter: brightness(0.8);
}

.icon {
  display: flex;
  align-items: center;
}

.icon img {
  width: 1.6875rem;
}

.text {
  flex: 1;
  text-align: center;
  color: var(--grey-scale-90);
  font: var(--button-text-md);
  letter-spacing: var(--button-text-md-letter-spacing);
}
