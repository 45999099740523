.login {
  position: relative;
  height: 100%;
  padding: 0 1.4375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.top {
  height: 65%;
  z-index: 1;
}

.logo {
  padding: 0.8125rem 0;
  margin-bottom: 1.8125rem;
}

.bottom {
  flex: 1;
  animation-duration: 1s;
  animation-name: login-slidein-bottom-to-top;
}

.loginButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}

.info {
  text-align: center;
  margin-top: 0.875rem;
  color: var(--grey-scale-60);
  font: var(--caption-md);
  letter-spacing: var(--caption-md-letter-spacing);
}

.signUp {
  margin-left: 0.5rem;
  color: var(--primary-skyblue);
  font: var(--button-text-sm);
  letter-spacing: var(--button-text-sm-letter-spacing);
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 380px) {
  .umbrella img {
    width: 80%;
  }
}

@keyframes login-slidein-bottom-to-top {
  0% {
    transform: translateY(100%);
  }

  50% {
    transform: translateY(100%);
  }

  80% {
    transform: translateY(-0.625rem);
  }

  100% {
    transform: translateY(0);
  }
}
