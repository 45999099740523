.container {
  padding: 0 1.4375rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.3125rem;
}

.content {
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
