.container {
  height: 100%;
  padding: 0 1.4375rem;
  display: flex;
  flex-direction: column;
}

.back {
  display: flex;
  padding: 0.625rem 0;
  margin-bottom: 1.8125rem;
}

.back img {
  cursor: pointer;
}

.content {
  padding-bottom: 1.4375rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2.375rem;
}
