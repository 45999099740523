.container {
  background-color: black;
}

.data {
  color: white;
}

.video {
  width: 100vw;
  max-width: 450px;
  max-height: 935px;
  min-height: 667px;
  height: 100vh;
  object-fit: 'cover';
}

.div {
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/scan_area.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
