.button {
  width: 100%;
  padding: 0.875rem 0;
  border-radius: 0.375rem;
  background-color: var(--primary-skyblue);
  color: var(--grey-scale-0);
  font: var(--button-text-sm);
  cursor: pointer;
}

.button:active {
  filter: brightness(0.8);
}

.button:disabled {
  background-color: var(--grey-scale-20);
  color: #8b9ba4;
  cursor: default;
}

.button:disabled:active {
  filter: none;
}

.greyButton {
  background-color: var(--grey-scale-20);
}

.greyText {
  color: var(--grey-scale-40);
}
