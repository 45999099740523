.button {
  width: 100%;
  padding: 1.25rem 0;
  background: var(--primary-skyblue);
  border-radius: 50px;
  font: var(--button-text-md-2);
  color: var(--grey-scale-0);
}

.button.white {
  background: var(--grey-scale-0);
  color: var(--grey-scale-45);
  border: 2px solid var(--grey-scale-15);
}
