.container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 23px;
}

.imageContainer {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  overflow-x: auto;
  overflow-y: hidden;
}

.fileInput label {
  width: 5.875rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 7px;
  border: 3px solid transparent;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #0bff99 31%, #38b9ff 98%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-sizing: border-box;
}

.fileInput input {
  display: none;
}

.amount {
  font: var(--caption-md);
  color: var(--grey-scale-30);
}

.imageWrapper {
  width: 4rem;
  height: 4rem;
}

.image {
  width: 4rem;
  height: 4rem;
  border-radius: 7px;
  object-fit: cover;
}

/* textarea */
.label {
  display: block;
  font: var(--text-md);
  letter-spacing: var(--text-md-letter-spacing);
  color: var(--grey-scale-90);
  margin-bottom: 0.1875rem;
}

.textarea {
  width: 100%;
  height: 12.5rem;
  padding: 0.8125rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--grey-scale-30);
  font: var(--text-lg);
  color: var(--grey-scale-90);
  letter-spacing: var(--text-lg-letter-spacing);
  outline: none;
  resize: none;
  box-sizing: border-box;
}

.textarea::placeholder {
  color: var(--grey-scale-20);
}

.textarea:focus {
  border-color: var(--grey-scale-90);
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.cancleButton {
  flex: 1;
}

.inquiryButton {
  flex: 2;
}

/* caution */
.caution {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.cautionTitleDiv {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.cautionTitle {
  font: var(--text-sm-emphasis);
  color: var(--grey-scale-40);
}

.arrow {
  transition: 200ms transform ease;
}

.description {
  opacity: 0;
  font: var(--text-sm);
  color: var(--grey-scale-40);
  transition: 200ms opacity ease;
  margin-bottom: 40px;
}

.description p {
  margin: 0;
  padding: 0;
}
