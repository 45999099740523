.codeFieldset {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.codeButton {
  display: flex;
  gap: 0.25rem;
  margin-top: 0.375rem;
}

.sendCodeButton {
  flex: 1;
}

.resendCodeButton {
  width: 23%;
}
