.listTitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.6875rem;
  font: var(--text-sm-emphasis);
  letter-spacing: var(--text-sm-emphasis-letter-spacing);
  background: var(--primary-skyblue);
  color: transparent;
}

.menu {
  padding: 0 1.4375rem;
}

.item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.0625rem 1.125rem;
  border-bottom: var(--grey-scale-10) 1px solid;
  font: var(--text-lg-emphasis);
  letter-spacing: var(--text-lg-emphasis-letter-spacing);
  color: var(--grey-scale-60);
}
